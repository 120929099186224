.rdw-editor-main {
  border: 1px solid #f1f1f1;
  min-height: 150px;
  padding: 0 15px;
}

.smooth-dnd-draggable-wrapper {
  cursor: move;
}

.smooth-dnd-ghost {
  left: 15px !important;
  background-color: #fff;
}
